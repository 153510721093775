import type { FunctionComponent, ReactNode } from 'react';
import { Suspense } from 'react';

/**
 * Shordhand for wrapper with className
 */
export function suspense<P>(Component: FunctionComponent<P>, fallback?: ReactNode): FunctionComponent<P> {
  const Wrapped: FunctionComponent<P> = props => <Suspense fallback={fallback} data-sentry-element="Suspense" data-sentry-component="Wrapped" data-sentry-source-file="suspense.tsx">
            <Component {...props as P} data-sentry-element="Component" data-sentry-source-file="suspense.tsx" />
        </Suspense>;
  Wrapped.displayName = `suspense(${Component.displayName ?? 'Component'})`;
  return Wrapped;
}
export type Suspender<P> = {
  readData(): P;
};
export function suspender<P>(promise: Promise<P>): Suspender<P> {
  let status = 'pending'; // status of execution
  let result: P; // data to be returned

  const suspender = promise.then(resp => {
    status = 'success';
    result = resp;
  }).catch(err => {
    status = 'error';
    result = err;
  });
  return {
    readData() {
      if (status === 'success') {
        return result; // return the data successfully fetched
      }
      if (status === 'error') {
        throw result; // throw the error and suspend the component
      }
      throw suspender; // suspend the component by throwing the suspender
    }
  };
}