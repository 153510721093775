import type { FunctionComponent } from 'react';
import type { ColorFragment } from '../../graphql/fragments/ColorFragment.graphql';
import { useText } from '../../graphql/hooks/text';
import { suspense } from '../../lib/utils/suspense';
import type { EntryMeta } from '../../types/sys';
import type { CustomRenderers, DocumentWithLinks } from './render';
import { renderDocumentWithLinks } from './render';

/**
 * React renderer for the Contentful rich text field type.
 * @see https://www.contentful.com/developers/docs/concepts/rich-text/
 */
export const RichText: FunctionComponent<RichTextProps> = ({
  source,
  color,
  renderers
}) => renderDocumentWithLinks(source, renderers, color);

/**
 * React renderer for the Contentful rich text field type.
 * @see https://www.contentful.com/developers/docs/concepts/rich-text/
 * Fetches the document and renders it.
 */
export const SelfFetchingRichText: FunctionComponent<EntryMeta & Pick<RichTextProps, 'renderers'>> = suspense(({
  sys,
  renderers
}) => {
  const {
    text: {
      content,
      color
    }
  } = useText(sys.id);
  if (!content) return null;
  return renderDocumentWithLinks(content, renderers, color);
});
SelfFetchingRichText.displayName = 'SelfFetchingRichText';
export type RichTextProps = {
  /**
   * Rich text document can be found in *.json property of a Rich text field.
   */
  source: DocumentWithLinks;

  /**
   * Custom renderers can be passed in to override the default ones.
   */
  renderers?: CustomRenderers;

  /**
   * Color of the text.
   */
  color?: ColorFragment;
};