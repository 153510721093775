import type { FunctionComponent } from 'mdx/types';
import { CardFlip } from '../../components/card-flip';
export enum DynamicElement {
  StepBlackCardFlip = 'step-black.card-flip',
}
export const DynamicElements = {
  [DynamicElement.StepBlackCardFlip]: CardFlip
};
const DynamicElementComponent: FunctionComponent<{
  type: string;
}> = ({
  type
}) => {
  const Component = DynamicElements[type];
  if (!Component) return null;
  return <Component data-sentry-element="Component" data-sentry-component="DynamicElementComponent" data-sentry-source-file="dynamic-element.tsx" />;
};
export default DynamicElementComponent;