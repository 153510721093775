/**
 * Accepts a string and returns a string with all inline superscript indicators replaced with <sup> tags
 * E.g. hello world^2 becomes hello world<sup>2</sup>
 */
export const replaceForSuperScript = (text: string) => {
    return text.replace(
        /(\^\d+)/g,
        (i, match) => `<sup>${match.replace('^', '')}</sup>`
    )
}

export const replaceForSymbol = (text: string) => {
    return (
        text
            .replaceAll(
                '~symU+030D',
                `<span class="symbol superscriptSymbol">&#781;</span>`
            )
            .replaceAll('~symU+002A', `<span class="symbol">&#42;</span>`)
            .replaceAll(
                '~symU+02D6',
                `<span class="symbol subscriptSymbol">&#726;</span>`
            )
            .replaceAll(
                '~symU+030E',
                `<span class="symbol superscriptSymbol">&#782;</span>`
            )
            .replaceAll(
                '~symU+033E',
                `<span class="symbol superscriptSymbol">&#830;</span>`
            )
            .replaceAll(
                '~symU+0351',
                `<span class="symbol superscriptSymbol">&#849;</span>`
            )
            .replaceAll(
                '~symU+030A',
                `<span class="symbol superscriptSymbol">&#778;</span>`
            )
            .replaceAll('~symU+02C6', `<span class="symbol">&#710;</span>`)
            .replaceAll(
                '~symU+033D',
                `<span class="symbol superscriptSymbol">&#829;</span>`
            )
            .replaceAll(
                '~symU+0342',
                `<span class="symbol superscriptSymbol">&#834;</span>`
            )
            .replaceAll(
                '~symU+034C',
                `<span class="symbol superscriptSymbol">&#844;</span>`
            )
            .replaceAll(
                '~symU+034B',
                `<span class="symbol superscriptSymbol">&#843;</span>`
            )
            .replaceAll('~symU+00AE', `<span class="symbol">&#174;</span>`)
            .replaceAll('~symU+0595', `<span class="symbol">&#1429;</span>`)
            .replaceAll(
                '~symU+05A0',
                `<span class="symbol magnifyingGlass">&#1440;</span>`
            )
            .replaceAll(
                '~symU+0346',
                `<span class="symbol superscriptSymbol">&#838;</span>`
            )
            .replaceAll(
                '~symU+035B',
                `<span class="symbol superscriptSymbol">&#859;</span>`
            )
            .replaceAll('~symU+2311', `<span class="symbol">&#8977;</span>`)
            .replaceAll('~symU+02C5', `<span class="symbol">&#709;</span>`)
            // superscript
            .replaceAll('~supsymU+030D', `<sup>&#781;</sup>`)
            .replaceAll('~supsymU+002A', `<sup>&#42;</sup>`)
            .replaceAll('~supsymU+02D6', `<sup>&#726;</sup>`)
            .replaceAll('~supsymU+030E', `<sup>&#782;</sup>`)
            .replaceAll('~supsymU+033E', `<sup>&#830;</sup>`)
            .replaceAll('~supsymU+0351', `<sup>&#849;</sup>`)
            .replaceAll('~supsymU+030A', `<sup>&#778;</sup>`)
            .replaceAll('~supsymU+02C6', `<sup>&#710;</sup>`)
            .replaceAll('~supsymU+033D', `<sup>&#829;</sup>`)
            .replaceAll('~supsymU+0342', `<sup>&#834;</sup>`)
            .replaceAll('~supsymU+034C', `<sup>&#844;</sup>`)
            .replaceAll('~supsymU+034B', `<sup>&#843;</sup>`)
            .replaceAll('~supsymU+00AE', `<sup>&#174;</sup>`)
            .replaceAll('~supsymU+0595', `<sup>&#1429;</sup>`)
            .replaceAll('~supsymU+05A0', `<sup>&#1440;</sup>`)
            .replaceAll('~supsymU+0346', `<sup>&#838;</sup>`)
            .replaceAll('~supsymU+035B', `<sup>&#859;</sup>`)
            .replaceAll('~supsymU+2311', `<sup>&#8977;</sup>`)
            .replaceAll('~supsymU+02C5', `<sup>&#709;</sup>`)
    )
}
