import { observer } from 'mobx-react';
import type { FunctionComponent } from 'react';
import { LinkButton } from '../../components/button/link';
import type { PRESETS } from '../../components/button/presets';
import type { ButtonTrackingProps } from '../../components/button/props';
import type { ActionDownloadFileFragment } from '../../graphql/fragments/ActionDownloadFileFragment.graphql';
import type { ActionRemoteUrlFragment } from '../../graphql/fragments/ActionRemoteUrlFragment.graphql';
import type { WithTestId } from '../../lib/utils/testid';

/** Action button to send the user to another website. */
export const ActionRemoteUrl: FunctionComponent<Props> = observer(({
  url,
  title,
  file,
  placement,
  loading,
  analyticsKey,
  containerElementId,
  ...restOfProps
}) => <LinkButton preset={restOfProps.preset} size='medium' href={url} target={url} placement={placement} containerElementId={containerElementId} download={file?.source} loading={loading} analyticsKey={analyticsKey} {...restOfProps}>
            {title}
        </LinkButton>);
type CommonProps<T> = Omit<T & WithTestId<ButtonTrackingProps & {
  loading?: boolean;
  preset?: keyof typeof PRESETS | string;
}>, 'sys' | 'type'>;
type Props = CommonProps<ActionDownloadFileFragment> & CommonProps<ActionRemoteUrlFragment>;