import classNames from 'classnames';
import type { FunctionComponent } from 'react';
import type { WithTestId } from '../../../lib/utils/testid';
import type { BrandColor } from '../../../types/color';
import type { IconProps } from '../types';
import styles from './play.module.scss';

/** ▶️ icon */
export const Play: FunctionComponent<React.PropsWithChildren<Props>> = ({
  width = '40',
  height = '40',
  color = 'step-white',
  secondaryColor = 'step-cold-steel',
  className,
  'data-testid': testId
}) => <svg data-testid={testId} width={width} height={height} className={classNames(styles.play, className, styles[`fill-test`])} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' data-sentry-element="svg" data-sentry-component="Play" data-sentry-source-file="index.tsx">
        <circle cx='20' cy='20' r='20' className={styles[`fill-${color}`]} data-sentry-element="circle" data-sentry-source-file="index.tsx" />
        <path d='M28 20L16 26.9282L16 13.0718L28 20Z' className={styles[`fill-${secondaryColor}`]} data-sentry-element="path" data-sentry-source-file="index.tsx" />
    </svg>;
interface Props extends WithTestId<IconProps> {
  /** Color of the circle. */
  color?: BrandColor;
  /** Color of the ▶ inside */
  secondaryColor?: BrandColor;
  /** Extra className for extra special cases. */
  className?: string;
}