import { makeUseQueryHook } from '../functions'
import { EMPTY_TEXT } from '../placeholders'
import { GetTextDocument } from '../queries/GetText.graphql'

export enum TextKey {}

/**
 * Fetches text for the given id.
 */
export const useText = (id: string) => {
    const result = useGetText({ variables: { id } })
    return { ...result, text: result.data.text ?? EMPTY_TEXT }
}

const useGetText = makeUseQueryHook({
    query: GetTextDocument,
})
