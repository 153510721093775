import type { PopupButton } from '@typeform/embed-react';
import type { FunctionComponent } from 'mdx/types';
import dynamic from 'next/dynamic';
import type { ActionOpenTypeformFragment } from '../../graphql/fragments/ActionOpenTypeformFragment.graphql';
import { tracker } from '../../lib/store/tracker/useTracker';
import { useStores } from '../../lib/store/useStores';
import styles from './action-typeform.module.scss';
const TypeformPopup = dynamic(() => import('@typeform/embed-react').then(m => m.PopupButton) as Promise<typeof PopupButton>, {
  ssr: false
});
export const ActionOpenTypeform: FunctionComponent<Props> = ({
  publicUrl,
  title,
  analyticsKey
}) => {
  const formId = publicUrl?.split('/').pop();
  const {
    deviceStore: {
      os
    }
  } = useStores();
  if (!formId) return null;
  return <TypeformPopup autoResize size={50} id={formId} className={styles.button} data-sentry-element="TypeformPopup" data-sentry-component="ActionOpenTypeform" data-sentry-source-file="action-typeform.tsx">
            <span onClick={() => tracker.clickButton(analyticsKey ?? title ?? 'typeform', {
      os
    })}>
                {title}
            </span>
        </TypeformPopup>;
};
type Props = Omit<ActionOpenTypeformFragment, 'sys'>;