import { observer } from 'mobx-react';
import type { FunctionComponent } from 'react';
import type { ButtonProps } from '../../components/button/button';
import type { ButtonTrackingProps } from '../../components/button/props';
import { Desktop, Mobile } from '../../components/responsive';
import type { ActionDownloadAppFragment } from '../../graphql/fragments/ActionDownloadAppFragment.graphql';
import { useStores } from '../../lib/store/useStores';
import type { WithTestId } from '../../lib/utils/testid';
import { DownloadApp } from '../forms/download-app';
import { ActionRemoteUrl } from './action-remote-url';
export const ActionDownloadApp: FunctionComponent<Props> = observer(({
  title: titleFromProps,
  analyticsKey,
  ...props
}) => {
  const stores = useStores();
  const {
    linkingStore: {
      appDownloadLink: link
    }
  } = stores;
  const {
    buttonProps
  } = props;
  return <>
                <Mobile>
                    <ActionRemoteUrl {...props} {...buttonProps} url={link ?? process.env.NEXT_PUBLIC_ORGANIC_DOWNLOAD_URL} title={titleFromProps} analyticsKey={analyticsKey} data-testid='getApp.button' />
                </Mobile>
                <Desktop>
                    <DownloadApp {...props} preset={props.preset} title={titleFromProps} analyticsKey={analyticsKey} />
                </Desktop>
            </>;
});
type Props = WithTestId<ButtonTrackingProps & ActionDownloadAppFragment & {
  size?: 'small' | 'medium';
  buttonProps?: Partial<ButtonProps>;
  loading?: boolean;
}>;