import { observer } from 'mobx-react';
import type { FunctionComponent } from 'react';
import type { ButtonProps } from '../../components/button/button';
import type { PRESETS } from '../../components/button/presets';
import type { ButtonTrackingProps } from '../../components/button/props';
import type { ActionDownloadAppFragment } from '../../graphql/fragments/ActionDownloadAppFragment.graphql';
import type { ActionDownloadFileFragment } from '../../graphql/fragments/ActionDownloadFileFragment.graphql';
import type { ActionLocalPathnameFragment } from '../../graphql/fragments/ActionLocalPathnameFragment.graphql';
import type { ActionOpenTypeformFragment } from '../../graphql/fragments/ActionOpenTypeformFragment.graphql';
import type { ActionRemoteUrlFragment } from '../../graphql/fragments/ActionRemoteUrlFragment.graphql';
import { usePersonalizations } from '../../lib/hooks/usePersonalizations';
import { renderTemplate } from '../../lib/utils/text';
import { ActionDownloadApp } from './action-download-app';
import { ActionLocalPathname } from './action-local-pathname';
import { ActionRemoteUrl } from './action-remote-url';
import { ActionOpenTypeform } from './action-typeform';

/** CMS-driven button that supports different types of actions. */
export const ActionButton: FunctionComponent<ActionProps> = observer(({
  title,
  ...restOfProps
}) => {
  const personalizations = usePersonalizations();
  const props = {
    ...restOfProps,
    title: renderTemplate(title, personalizations)
  };
  switch (props.type) {
    case 'ActionDownloadFile':
    case 'ActionRemoteUrl':
      return <ActionRemoteUrl {...props} />;
    case 'LocalPathnameAction':
      return <ActionLocalPathname {...props} />;
    case 'Typeform':
      return <ActionOpenTypeform {...props} />;
    default:
      return <ActionDownloadApp {...props as ActionDownloadAppFragment} />;
  }
});
type CommonProps = ButtonTrackingProps & {
  buttonProps?: Partial<ButtonProps>;
  size?: 'small' | 'medium';
  outline?: boolean;
  preset?: keyof typeof PRESETS | string;
  forceExpanded?: boolean;
};
type Props<T> = Omit<T & CommonProps, 'sys'>;
export type ActionProps = Props<ActionDownloadAppFragment> | Props<ActionDownloadFileFragment> | Props<ActionLocalPathnameFragment> | Props<ActionRemoteUrlFragment> | Props<ActionOpenTypeformFragment>;